<template>
  <v-container
    fluid
    class="fill-height pa-3 pa-sm-12 ma-0"
    :style="gradientStyle"
  >
    <v-row no-gutters>
      <v-col cols="12" sm="9">
        <h1
          class="primary--text text-h5 text-sm-h4 text-md-h3 font-weight-bold text-uppercase"
        >
          <v-icon large class="primary--text text-h5 text-sm-h4 text-md-h3">
            {{ iconName }}
          </v-icon>
          {{ title }}
        </h1>
        <h2
          v-if="subtitle"
          class="text-h6 text-sm-h6 text-md-h5 font-weight-thin"
        >
          {{ subtitle }}
        </h2>
      </v-col>
      <v-col cols="12" sm="3" class="white--text d-flex align-end">
        <slot />
        <v-breadcrumbs></v-breadcrumbs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageTitle',

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'label'
    }
  },

  computed: {
    gradientStyle() {
      let color = this.$vuetify.theme.themes.light.primary
      return `background: linear-gradient(90deg, white 0%, white 40%, ${color} 100%);`
    },
    iconName() {
      return 'mdi-' + this.icon
    }
  }
}
</script>
